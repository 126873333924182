import React, { useEffect, useState, useRef, useContext } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";

// Images
import RightDoubleArrowIcon from "../assets/icons/right-double-arrow-icon.svg";
import Logo from "../assets/img/logo-1.png";
import BabyBeraAudioWav from "../assets/audio/babybera-music.wav";
import BabyBeraAudioMP3 from "../assets/audio/babybera-music.mp3";

import { MdClose, MdKeyboardArrowRight, MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";

import { FaDiscord, FaMediumM, FaTwitter, FaPlay } from "react-icons/fa";
import { VscPlay, VscDebugPause } from "react-icons/vsc";
import HomeIcon from "../assets/img/homeicon.png";
import MintIcon from "../assets/img/minticon.png";
import StakeIcon from "../assets/img/stakeicon.png";
import FarmIcon from "../assets/img/farmicon.png";
import DownloadIcon from "../assets/img/download.png";
import { ThemeContext } from "../ThemeContext";
import { BsMoon, BsSun } from "react-icons/bs";
import { AudioContext } from "../AudioContext";

const Header = () => {
  const { isPlaying, togglePlay } = useContext(AudioContext);
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const [isHeaderShowing, setIsHeaderShowing] = useState(false);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const ad = useRef();

  return (
    <header>
      <div className="box">
        <div className="header-content">
          <audio id="myAudio" ref={ad} loop>
            <source src={BabyBeraAudioWav} type="audio/wav" />
            <source src={BabyBeraAudioMP3} type="audio/mp3" />
          </audio>
          <div className="header-left">
            <Link to="/">
              <div></div>
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="header-center">
            <Link to="/">
              <div></div> <span>HOME</span>
            </Link>
            <Link to="/mint">
              <div></div> <span>MINT</span>
            </Link>
            <Link to="/stake">
              <div></div> <span>STAKE</span>
            </Link>
            <Link to="/farms">
              <div></div> <span>FARM</span>
            </Link>
            <Link to="/download">
              <div></div> <span>DOWNLOAD</span>
            </Link>
          </div>
          <div className="header-right">
            <div className="header-right-box">
              {/* <div className="light-dark-theme-box">
                {isDarkTheme ? (
                  <div
                    className="theme-icon"
                    onClick={() => {
                      toggleTheme();
                    }}
                  >
                    <BsSun />
                  </div>
                ) : (
                  <div
                    className="theme-icon active-theme"
                    onClick={() => {
                      toggleTheme();
                    }}
                  >
                    <BsSun />
                  </div>
                )}
                {isDarkTheme ? (
                  <div
                    className="theme-icon active-theme"
                    onClick={() => {
                      toggleTheme();
                    }}
                  >
                    <BsMoon />
                  </div>
                ) : (
                  <div
                    className="theme-icon"
                    onClick={() => {
                      toggleTheme();
                    }}
                  >
                    <BsMoon />
                  </div>
                )}
              </div> */}
              <button className="bb-btn-grd">
                <span>
                  <i> Connect Wallet</i>
                  <img src={RightDoubleArrowIcon} alt="RightDoubleArrowIcon" />
                </span>
              </button>
            </div>
            <div className="header-vertical-icons-box">
              <div className="header-vertical-icons">
                <Link to="/discord">
                  <a
                    target={"_blank"}
                    href="https://twitter.com/babyberafinance"
                  >
                    <FaTwitter />
                  </a>
                </Link>
                <a target={"_blank"} href="https://medium.com/@babybera ">
                  <FaMediumM />
                </a>
                {isPlaying ? (
                  <div
                    onClick={() => {
                      togglePlay();
                      // pauseAudio();
                      setIsMusicPlaying(false);
                      ad.current.pause();
                    }}
                  >
                    <VscDebugPause />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      togglePlay();
                      // playAudio();
                      setIsMusicPlaying(true);
                      ad.current.play();
                    }}
                  >
                    <VscPlay />
                  </div>
                )}
              </div>
            </div>{" "}
          </div>
          <div className="header-mob-icon">
            <span
              onClick={() => {
                setIsHeaderShowing(true);
              }}
            >
              <MdMenu />
            </span>
          </div>
        </div>
      </div>
      {isHeaderShowing ? (
        <div className="header-mob">
          <div className="hm-box-1"></div>
          <div className="hm-box-2"></div>
          <div className="hm-box-3"></div>
          <div className="hm-box-4"></div>
          <div className="box">
            <div className="header-mob-head">
              <img src={Logo} alt="Logo" />
              <span
                onClick={() => {
                  setIsHeaderShowing(false);
                }}
              >
                <MdClose />
              </span>
            </div>
            <div className="header-mob-body">
              <div className="header-mob-body-content">
                <div
                  onClick={() => {
                    setIsHeaderShowing(false);
                  }}
                >
                  <Link to="/">
                    <div></div>{" "}
                    <span>
                      {" "}
                      <img src={HomeIcon} alt="" /> HOME
                    </span>
                  </Link>
                </div>
                <div
                  onClick={() => {
                    setIsHeaderShowing(false);
                  }}
                >
                  <Link to="/mint">
                    <div></div>{" "}
                    <span>
                      {" "}
                      <img
                        src={MintIcon}
                        alt=""
                        className="mint-mob-img"
                      />{" "}
                      MINT
                    </span>
                  </Link>
                </div>
                <div
                  onClick={() => {
                    setIsHeaderShowing(false);
                  }}
                >
                  <Link to="/stake">
                    <div></div>{" "}
                    <span>
                      {" "}
                      <img src={StakeIcon} alt="" /> STAKE
                    </span>
                  </Link>
                </div>
                <div
                  onClick={() => {
                    setIsHeaderShowing(false);
                  }}
                >
                  <Link to="/farms">
                    <div></div>{" "}
                    <span>
                      {" "}
                      <img src={FarmIcon} alt="" /> FARM
                    </span>
                  </Link>
                </div>
                <div
                  onClick={() => {
                    setIsHeaderShowing(false);
                  }}
                >
                  <Link to="/download">
                    <div></div>{" "}
                    <span>
                      {" "}
                      <img src={DownloadIcon} alt="" /> DOWNLOAD
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
