import React, { useEffect, useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/comingsoon.css";

// Images
import Logo from "../assets/img/logo-1.png";
import DiscordIcon from "../assets/icons/Discord.svg";
import PlayIcon from "../assets/icons/PlayIcon.svg";
import TwitterIcon from "../assets/icons/Twitter.svg";
import BabyBeraImg from "../assets/img/desk.png";
import BeraEyesOpen from "../assets/img/aaa.png";
import BeraEyesClosed from "../assets/img/bbb.png";
import YieldFarmImg from "../assets/img/6.png";
import BlueCircleSmallImg from "../assets/img/blue-circle-small.png";
import L1Img from "../assets/img/l1.png";
import L2Img from "../assets/img/l2.png";
import L3Img from "../assets/img/l3.png";
import L4Img from "../assets/img/l4.png";
import L5Img from "../assets/img/l5.png";
import R1Img from "../assets/img/r1.png";
import R2Img from "../assets/img/r2.png";
import R3Img from "../assets/img/r3.png";
import R4Img from "../assets/img/r4.png";
import R5Img from "../assets/img/r5.png";
import T1Img from "../assets/img/t1.png";
import T2Img from "../assets/img/t2.png";
import T3Img from "../assets/img/t3.png";
import T4Img from "../assets/img/t4.png";
import BabyBeraAudioWav from "../assets/audio/babybera-music.wav";
import BabyBeraAudioMP3 from "../assets/audio/babybera-music.mp3";

// Components
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import $ from "jquery";
import { FaDiscord, FaMediumM, FaTwitter, FaPlay } from "react-icons/fa";
import { VscPlay, VscDebugPause } from "react-icons/vsc";
import { MdOutlineRotate90DegreesCcw } from "react-icons/md";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  gsap.registerPlugin(Draggable, InertiaPlugin);

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const ad = useRef();
  const pauseAudio = () => {
    setIsMusicPlaying(false);
    ad.current.pause();
  };
  const playAudio = () => {
    setIsMusicPlaying(true);
    ad.current.play();
  };

  useEffect(() => {
    Draggable.create(
      ".cs-t1-img,.cs-t2-img,.cs-l1-img, .cs-l2-img, .cs-r1-img, .cs-r2-img",
      {
        type: "rotation",
        edgeResistance: 1,
        bounds: ".coming-soon-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    Draggable.create(
      ".cs-t3-img,.cs-t4-img,.cs-l3-img, .cs-l4-img, .cs-l5-img, .cs-r3-img, .cs-r4-img, .cs-r5-img",
      {
        type: "x,y",
        edgeResistance: 1,
        bounds: ".coming-soon-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    var droppables = $(".cs-draggable-shapes");
    var dropArea = $("#dropArea");
    var overlapThreshold = "99%";
    Draggable.create(droppables, {
      bounds: window,
      onDrag: function (e) {
        if (this.hitTest(dropArea, overlapThreshold)) {
          $(this.target).addClass("highlight");
        } else {
          $(this.target).removeClass("highlight");
        }
      },
      onDragEnd: function (e) {
        if (!$(this.target).hasClass("highlight")) {
          let CSShapesDrag = gsap.timeline();
          CSShapesDrag.to(this.target, 0.2, {
            x: 0,
            y: 0,
          });
        }
      },
    });

    gsap.fromTo(
      ".cs-dot",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        repeat: -1,
        duration: 1,
        stagger: {
          each: 0.5,
        },
      }
    );

    /* gsap.fromTo(
        ".loading-screen",
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.05,
        }
      ); */
    let CSShapesAnimRight = gsap.timeline();
    CSShapesAnimRight.fromTo(
      ".cs-r1-img",
      {
        y: "100vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".cs-r2-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-r3-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-r4-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-r5-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".bera-eyes-open",
        {
          opacity: 1,
        },
        {
          duration: 0.25,
          opacity: 0,
        }
      );

    let CSShapesAnimTop = gsap.timeline();
    CSShapesAnimTop.fromTo(
      ".cs-t1-img",
      {
        y: "100vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".cs-t2-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-t3-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-t4-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      );

    // If media query matches
    let CSShapesAnimLeftMob = gsap.timeline();
    CSShapesAnimLeftMob.fromTo(
      ".cs-left-shapes-box-mob .cs-l1-img",
      {
        y: "100vh",
      },
      {
        y: "0",
        duration: 1.5,
        ease: Back.easeInOut,
        delay: 0.5,
      }
    )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l2-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l3-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l4-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l5-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      );

    let CSShapesAnimLeftPC = gsap.timeline();
    CSShapesAnimLeftPC.fromTo(
      ".cs-left-shapes-box-pc .cs-l1-img",
      {
        y: "-100vh",
      },
      {
        y: "0",
        duration: 1.5,
        ease: Back.easeInOut,
      }
    )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l2-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l3-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l4-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l5-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      );
  }, []);

  return (
    <main className="babybera-app">
      <div className="rotate-screen">
        <MdOutlineRotate90DegreesCcw />
        <p>Please rotate your screen to vertical view.</p>
      </div>
      <audio id="myAudio" ref={ad} loop>
        <source src={BabyBeraAudioWav} type="audio/wav" />
        <source src={BabyBeraAudioMP3} type="audio/mp3" />
      </audio>
      <div className="coming-soon-page">
        {/* <div className="cs-grd-circle-1"></div> */}
        {/* <div className="cs-grd-circle-2"></div> */}
        <div className="box">
          <section className="coming-soon-wrapper">
            <header className="cs-header">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
              <div className="cs-header-icons-box">
                <Link to="/discord">
                  <a target={"_blank"}>
                    <FaDiscord />
                  </a>
                </Link>

                <a target={"_blank"} href="https://twitter.com/babyberafinance">
                  <FaTwitter />
                </a>
                <a target={"_blank"} href="https://medium.com/@babybera ">
                  <FaMediumM />
                </a>
                {isMusicPlaying ? (
                  <div
                    onClick={() => {
                      pauseAudio();
                      setIsMusicPlaying(false);
                    }}
                  >
                    <VscDebugPause />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      playAudio();
                      setIsMusicPlaying(true);
                    }}
                  >
                    <VscPlay />
                  </div>
                )}
              </div>
            </header>
            <div className="cs-content">
              <div className="cs-babybera-box">
                <img
                  className="bera-desk"
                  src={BabyBeraImg}
                  alt="BabyBeraImg"
                />
                <img
                  className="bera-eyes-open"
                  src={BeraEyesOpen}
                  alt="BabyBeraImg"
                />
                <img
                  className="bera-eyes-closed"
                  src={BeraEyesClosed}
                  alt="BabyBeraImg"
                />
              </div>
              <div className="cs-heading-box">
                <h1>COMING SOON</h1>
                <div className="cs-dot-box">
                  <div className="cs-dot"></div>
                  <div className="cs-dot"></div>
                  <div className="cs-dot"></div>
                </div>
              </div>
              <div className="cs-berachain-box">
                <div className="cs-next-text-box">
                  <Swiper
                    slidesPerView={"1"}
                    freeMode={false}
                    modules={[Autoplay, FreeMode]}
                    className="mySwiper"
                    loop={true}
                    speed={2000}
                    autoplay={{
                      delay: 10,
                      disableOnInteraction: true,
                      reverseDirection: true,
                    }}
                    direction={"vertical"}
                  >
                    <SwiperSlide>
                      <span>nft</span>
                    </SwiperSlide>
                    <SwiperSlide>
                      <span>music</span>
                    </SwiperSlide>
                    <SwiperSlide>
                      <span>token</span>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <span>on</span>
                <a target={"_blank"} href="https://berachain.com/">
                  <img src={YieldFarmImg} alt="BabyBera Shape" />
                </a>
              </div>
            </div>
            <div className="cs-left-shapes-box cs-left-shapes-box-pc">
              <img
                className="cs-l1-img  cs-rotatable-shapes cs-shapes"
                src={L1Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l2-img  cs-rotatable-shapes cs-shapes"
                src={L2Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l3-img cs-draggable-shapes cs-shapes"
                src={L3Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l4-img cs-draggable-shapes cs-shapes"
                src={L4Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l5-img cs-draggable-shapes cs-shapes"
                src={L5Img}
                alt="BabyBera Shape"
              />
            </div>
            <div className="cs-left-shapes-box cs-left-shapes-box-mob">
              <img
                className="cs-l1-img  cs-rotatable-shapes cs-shapes"
                src={L1Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l2-img  cs-rotatable-shapes cs-shapes"
                src={L2Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l3-img cs-draggable-shapes cs-shapes"
                src={L3Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l4-img cs-draggable-shapes cs-shapes"
                src={L4Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-l5-img cs-draggable-shapes cs-shapes"
                src={L5Img}
                alt="BabyBera Shape"
              />
            </div>
            <div className="cs-right-shapes-box">
              <img
                className="cs-r1-img cs-rotatable-shapes cs-shapes"
                src={R1Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-r2-img cs-rotatable-shapes cs-shapes"
                src={R2Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-r3-img cs-draggable-shapes cs-shapes"
                src={R3Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-r4-img cs-draggable-shapes cs-shapes"
                src={R4Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-r5-img cs-draggable-shapes cs-shapes"
                src={R5Img}
                alt="BabyBera Shape"
              />
            </div>
            <div className="cs-top-shapes-box">
              <img
                className="cs-t1-img cs-rotatable-shapes cs-shapes"
                src={T1Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-t2-img cs-rotatable-shapes cs-shapes"
                src={T2Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-t3-img cs-draggable-shapes cs-shapes"
                src={T3Img}
                alt="BabyBera Shape"
              />
              <img
                className="cs-t4-img cs-draggable-shapes cs-shapes"
                src={T4Img}
                alt="BabyBera Shape"
              />
            </div>
          </section>
          <div id="dropArea"></div>
        </div>
      </div>
    </main>
  );
};

export default ComingSoon;

/*
light 200
book 300
nothing 400
heavy 500
bold 600
extrabold 700
*/
{
  /* <section className="what-is-section">
          <div className="box">
            <div className="what-is-section-content">
              <div className="wisc-left">
                <h2>What is BabyBera Finance</h2>
                <p>
                  Find out why we 're your new favorite crypto app with some
                  help from our world-class partners.
                </p>
                <button className="bb-btn-grd">
                  <span>
                    <i>Connect Wallet</i>
                    <img
                      src={RightDoubleArrowIcon}
                      alt="RightDoubleArrowIcon"
                    />
                  </span>
                </button>
              </div>
              <div className="wisc-right"></div>
            </div>
          </div>
        </section>
        <section className="announcements-section">
          <div className="box">
            <div className="announcements-grid">
              <div className="ann-grid-left">
                <h4>Announcements</h4>
                <div className="bb-twitter-feed-box"></div>
              </div>
              <div className="ann-grid-right">
                <h4>Farms & Staking</h4>
                <div className="farms-staking-locked-box"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="subscribe-section">
          <div className="box">
            <div className="subscribe-section-content">
              <h4>Stay up to date with BabyBera</h4>
              <div className="subscribe-box">
                <input type="email" placeholder="Email Address" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="box">
            <div className="footer-grid">
              <div className="footer-left">
                <img src={Logo2} alt="Logo2" />
                <div className="footer-icons-box">
                  <a target={"_blank"} href="https://discord.gg/n4qGqZy4NH">
                    <FaDiscord />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://twitter.com/babyberafinance"
                  >
                    <FaTwitter />
                  </a>
                  <a target={"_blank"} href="https://medium.com/@babybera ">
                    <FaMediumM />
                  </a>
                </div>
              </div>
              <div className="footer-center">
                <div className="fc-grid">
                  <div className="fc-grid-box">
                    <h6>About</h6>
                    <a href="#">Team</a>
                    <a href="#">Careers</a>
                    <a href="#">Privacy</a>
                    <a href="#">Cookie Policy</a>
                    <a href="#">Terms</a>
                    <a href="#">Security</a>
                    <a href="#">Contact</a>
                  </div>
                  <div className="fc-grid-box">
                    <h6>Resources</h6>
                    <a href="#">Blog</a>
                    <a href="#">Knowledge Base</a>
                    <a href="#">Bug Bounty</a>
                    <a href="#">Oracles</a>
                    <a href="#">Referrals</a>
                    <a href="#">Brand Assets</a>
                    <a href="#"></a>
                  </div>
                  <div className="fc-grid-box">
                    <h6>Products</h6>
                    <a href="#">Wallet</a>
                    <a href="#">Farms</a>
                    <a href="#">Nests</a>
                    <a href="#">Bonds</a>
                    <a href="#">Liquidity</a>
                  </div>
                </div>
              </div>
              <div className="footer-right"></div>
            </div>
          </div>
        </footer> */
}

/*

1. Discuss every page, exact copy paste?, i have lot of ideas, will make videos on loom and will share so we decide together, i will have a lot of ideas you have to control them.
2. 3000$ budget
3. making animations so 1000$ every 5 pages
4. a lot of animations so dont get mad when see bugs
5. assist me with design

*/
